import React, { Fragment } from "react";
import Nav from '../components/Nav';
import { Link, graphql } from "gatsby";
import SubscribeBox from '../components/SubscribeBox';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import Img from "gatsby-image";
const twitterUrl = 'https://twitter.com/';

export default function BlogPost({data}) {
	const post = data.markdownRemark;
	const otherPosts = data.allMarkdownRemark.nodes.filter(p => p.id !== post.id).slice(0,3);
	return (
		<div>
			<SEO 
				title={post.frontmatter.title}
				description={post.frontmatter.description}
				image={post.frontmatter.img.publicURL}
				pathname={post.fields.slug}
				twitterUsername = { post.frontmatter.authorLink && post.frontmatter.authorLink.includes(twitterUrl) ? `@${post.frontmatter.authorLink.replace(twitterUrl,'')}` : null}
				article={true}
			/>
			<Nav />
			<div className='page'>
				<div className='post'>
					<div className='post__header'>
						<h1 className='post__title'>{post.frontmatter.title}</h1>
						<div className='post__details'>
							<span>{post.frontmatter.date}</span>
							{
								!!post.frontmatter.author && !! post.frontmatter.authorLink
								?( <Fragment>
									<span className='post__details__divider'> · </span>
									<span>By <a 
										href={post.frontmatter.authorLink}
										target="_blank" rel='noreferrer noopener' 
										className="post__details__link">
										{post.frontmatter.author}
									</a></span>
								</Fragment> ) : null
							}
						</div>
						<Img fluid={post.frontmatter.img.childImageSharp.fluid}
							alt={post.frontmatter.title + " image"}
							className='post__image'
						 />
					</div>
					<div className='postInner' dangerouslySetInnerHTML={{ __html: post.html }} />
					<SubscribeBox />
				</div>
				<div className='moreposts'>
					<div className='container'>
						<h4 className='posts__title'>Other posts</h4>
						<div className='postlist'>
							{otherPosts.map((node) => (
								<div key={node.id} className='postlist__item postlist__item--nomar'>
									<Link to={node.fields.slug}>
									<div className='postlist__item__media'>
										<Img fluid={node.frontmatter.img.childImageSharp.fluid}
											alt={node.frontmatter.title + " image"}
											className='postlist__item__img'
										 />
									</div>
									</Link>
									<div className='postlist__item__data'>
										<div className='postlist__item__data__category'>{node.frontmatter.category}</div>
									</div>
									<h3 className='postlist__item__title'>{node.frontmatter.title}</h3>
								</div>
							))}
							<div className='postlist__item--ghost'></div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	)
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			id
			fields{
				slug
			}
			frontmatter {
				title
				date(formatString: "MMMM D, YYYY")
				author
				authorLink
				description
				img {
					publicURL
					childImageSharp {
						fluid(maxWidth: 800) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
		allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 4) {
			nodes {
				id
				fields{
					slug
				}
				frontmatter {
					title
					category
					img {
						publicURL
						childImageSharp {
							fluid(maxHeight: 200) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`