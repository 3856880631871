import React from "react";

export default function SubscribeBox() {
	return (
		<div className='subscribeBox'>
			<h4 className='subscribeBox__title'>Keep up with unDraw Creatives</h4>
			<p className='subscribeBox__text'>Get new articles and updates on our Twitter account</p>
			<div>
				<a href='https://twitter.com/unDraw_co' target="_blank" rel='noreferrer noopener' className='subscribeBox__btn'>Follow on Twitter</a>
			</div>
			{/*
				<h4 className='subscribeBox__title'>Subscribe to unDraw Creatives</h4>
				<p className='subscribeBox__text'>Receive the weekly newsletter and special announcements in your email</p>
				<div>
					<a href='/' className='subscribeBox__btn'>Subscribe</a>
				</div>
			*/}
		</div>
	)
}
